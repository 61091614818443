import React from "react";
import { Menu, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { useTranslate } from "react-translate";
import appUse from "../../useApp";
import PubSub from "pubsub-js";

import styles from "./Footer.module.scss";

const Footer = () => {
  const t = useTranslate("common");
  const { setLang } = appUse();

  const setLanguage = item => {
    setLang(item.key);
    localStorage.setItem("lang", item.key);
    document.documentElement.lang = item.key;
    PubSub.publish("LANG_CHANGED", item.key);
  };

  let language = localStorage.getItem("lang");
  if (language == null) {
    language = "tr";
  }

  const langMenu = (
    <Menu onClick={setLanguage}>
      <Menu.Item key="en">{t("en")}</Menu.Item>
      <Menu.Item key="tr">{t("tr")}</Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.root}>
      <div className="container">
        <div className={styles.left}>
          <ul>
            <li>
              <Link to="/">{t("home")}</Link>
            </li>
            <li>
              <a href={language === "tr" ? "/tr/nasil-calisir" : "/en/how-it-works"}>{t("what")}</a>
            </li>
            <li>
             <a href="https://blog.alivex.com" target="_blank">{t("texts")}</a>
            </li>
            <li>
              <a href="https://startupjobs.istanbul/sirketler/alivex/" target="_blank">{t("career")}</a>
            </li>
            <li>
              <a href={language === "tr" ? "/tr/organizer" : "/en/organizer"} >{t("organizer")}</a>
            </li>
            <li>
            <a href={language === "tr" ? "/tr/iletisim" : "/en/contact"} >{t("contact")}</a>
            </li>
          </ul>
        </div>
        <div className={styles.right}>
          <ul>
            <li>
              <a target="_blank" href={language === "tr" ? "https://alivex-cdn.s3.eu-central-1.amazonaws.com/agreements/ALIVEX_Terms_of_Use_TR.pdf": 
              "https://alivex-cdn.s3.eu-central-1.amazonaws.com/agreements/ALIVEX_Terms_of_Use_TR.pdf"}>{t("termsOfUse")}</a>
            </li>
            <li>
              <a target="_blank" href={language === "tr" ? "https://alivex-cdn.s3.eu-central-1.amazonaws.com/agreements/ALIVEX_KVKK_Policy_TR.pdf" 
              : "https://alivex-cdn.s3.eu-central-1.amazonaws.com/agreements/ALIVEX_KVKK_Policy_EN.pdf"} >{t("personalDataPolicy")}</a>
            </li>
            <li>
            <a target="_blank" href={language === "tr" ? "https://alivex-cdn.s3.eu-central-1.amazonaws.com/agreements/ALIVEX_CookiePolicy_TR.pdf" 
            : "https://alivex-cdn.s3.eu-central-1.amazonaws.com/agreements/ALIVEX_CookiePolicy_EN.pdf"} >{t("cookiePolicy")}</a>
            </li>
            <li>
              <a href={language === "tr" ? "/tr/sss" : "/en/faq"}>{t("faq")}</a>
            </li>
          </ul>

          <Dropdown overlay={langMenu} placement="topCenter">
            <button className={styles.langButton} onClick={() => {}} />
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Footer;
