import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import { useMediaQuery } from "react-responsive";
import useWindowScrollPosition from "@rehooks/window-scroll-position";
import cx from "classnames";
import { useTranslate } from "react-translate";
import ApiService from "../../services/ApiService";

import { Button } from "../";

import useOutsideClick from "../../utils/useOutsideClick";
import useApp from "../../useApp";

import styles from "./Header.module.scss";
import PubSub from "pubsub-js";

const Header = () => {
  const t = useTranslate("common");
  const { openSearch, setLang } = useApp();
  const ref = useRef();
  const [menuVisible, setMenuVisible] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const videoContent = document.querySelector("home-video");
  const options = {
    throttle: 100
  };
  const position = useWindowScrollPosition(options);
  const isFixed =
    position.y >= (videoContent ? videoContent.scrollHeight : 400);
  const hide = position.y >= 120;

  useOutsideClick(ref, () => {
    if (menuVisible) {
      setMenuVisible(false);
    }
  });

  const isMobile = useMediaQuery({ query: "(max-width: 991px)" });

  const setLanguage = item => {
    setLang(item.key);
    localStorage.setItem("lang", item.key);
    document.documentElement.lang = item.key;
    PubSub.publish("LANG_CHANGED", item.key);
  };

  let language = localStorage.getItem("lang");
  if (language == null) {
    language = "tr";
  }

  useEffect(() => {
    const checkLogin = async () => {
      const result = await ApiService.getUser();
      if (result == null) {
        setLoggedIn(false);
        return;
      }

      setLoggedInUser(result.Item);
      setLoggedIn(true);
    };

    checkLogin();
  }, []);

  const langMenu = (
    <Menu onClick={setLanguage}>
      <Menu.Item key="en">{t("en")}</Menu.Item>
      <Menu.Item key="tr">{t("tr")}</Menu.Item>
    </Menu>
  );

  const siteInfoMenu = (
    <ul>
      <li>
        <a
          target="_blank"
          href={
            language === "tr"
              ? "https://alivex-cdn.s3.eu-central-1.amazonaws.com/agreements/ALIVEX_Terms_of_Use_TR.pdf"
              : "https://alivex-cdn.s3.eu-central-1.amazonaws.com/agreements/ALIVEX_Terms_of_Use_TR.pdf"
          }
        >
          {t("termsOfUse")}
        </a>
      </li>
      <li>
        <a
         target="_blank"
          href={
            language === "tr"
              ? "https://alivex-cdn.s3.eu-central-1.amazonaws.com/agreements/ALIVEX_KVKK_Policy_TR.pdf"
              : "https://alivex-cdn.s3.eu-central-1.amazonaws.com/agreements/ALIVEX_KVKK_Policy_EN.pdf"
          }
        >
          {t("personalDataPolicy")}
        </a>
      </li>
      <li>
        <a
         target="_blank"
          href={
            language === "tr"
              ? "https://alivex-cdn.s3.eu-central-1.amazonaws.com/agreements/ALIVEX_CookiePolicy_TR.pdf"
              : "https://alivex-cdn.s3.eu-central-1.amazonaws.com/agreements/ALIVEX_CookiePolicy_EN.pdf"
          }
        >
          {t("cookiePolicy")} 
        </a>
      </li>
      <li>
        <a href={
            language === "tr"
              ? "/tr/sss"
              : "/en/faq"
          }>{t("faq")}</a>
      </li>
    </ul>
  );

  const siteDefaultMenu = (
    <ul>
      <li>
        <Link
          to="/"
          onClick={() => setMenuVisible(false)}
          className={styles.active}
        >
          {t("home")}
        </Link>
      </li>
      <li>
        <a
          href={
            language === "tr"
              ? "/tr/nasil-calisir"
              : "/en/how-it-works"
          }
          onClick={() => setMenuVisible(false)}
        >
          {t("what")}
        </a>
      </li>
      <li>
        <a
          href="https://blog.alivex.com/"
          target="_blank"
          onClick={() => setMenuVisible(false)}
        >
          {t("texts")}
        </a>
      </li>
      <li>
        <a
          href="https://startupjobs.istanbul/sirketler/alivex/"
          target="_blank"
          onClick={() => setMenuVisible(false)}
        >
          {t("career")}
        </a>
      </li>
      <li>
        <a
          href={
            language === "tr"
              ? "/tr/organizer"
              : "/en/organizer"
          }
          onClick={() => setMenuVisible(false)}
        >
          {t("organizer")}
        </a>
      </li>
      <li>
        <a
          href={
            language === "tr"
              ? "/tr/iletisim"
              : "/en/contact"
          }
          onClick={() => setMenuVisible(false)}
        >
          {t("contact")}
        </a>
      </li>
    </ul>
  );
  const userMenu = (
    <ul>
      <li>
        <a href="/profile" onClick={() => setMenuVisible(false)}>
          {t("myProfile")}
        </a>
      </li>
      <li>
        <a href="/activity-list" onClick={() => setMenuVisible(false)}>
          {t("events")}
        </a>
      </li>
      <li>
      <a href={
            language === "tr"
              ? "/tr/antrenman-programim"
              : "/en/training-program"
          }>{t("trainingProgram")}</a>
      </li>
      <li className={styles.logout}>
        <a href="/logout">{t("logout")}</a>
      </li>
    </ul>
  );

  return (
    <div
      className={cx(styles.root, {
        [styles.fixed]: isFixed,
        [styles.hide]: hide
      })}
    >
      <div className="container">
        <div className={styles.content}>
          <div className={styles.logo}>
            <img
              src="https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/logo-white.svg"
              alt=""
            />
          </div>
          <div className={styles.actions}>
            {!isMobile && !loggedIn && (
              <a href="/login?returnUrl=%2F">
                <Button color="white" className={styles.loginButton}>
                  {t("login")}
                </Button>
              </a>
            )}

            {isMobile ? (
              <Button
                color="white"
                className={styles.searchButton}
                onClick={openSearch}
              >
                <img
                  src="https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/search-white.svg"
                  alt=""
                />
              </Button>
            ) : (
              <Button
                color="white"
                className={styles.searchButton}
                onClick={openSearch}
              >
                <span>{t("search")}</span> "{t("searchKey")}"{" "}
                <img
                  src="https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/search-white.svg"
                  alt=""
                  className={styles.stickySearchImage}
                />
              </Button>
            )}
            <button
              className={styles.menuOpenerButton}
              onClick={() => setMenuVisible(!menuVisible)}
            >
              <img
                src="https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/menu-opener.svg"
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
      <div
        ref={ref}
        className={cx(styles.sideMenu, { [styles.open]: menuVisible })}
      >
        <div className={styles.actions}>
          <div>
            {!loggedIn && (
              <a href="/login?returnUrl=%2F">
                <Button color="white" className={styles.loginButton}>
                  {t("login")}
                </Button>
              </a>
            )}
          </div>
          <button
            className={styles.menuOpenerButton}
            onClick={() => setMenuVisible(false)}
          >
            <img
              src="https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/red-close.svg"
              alt=""
            />
          </button>
        </div>
        <div>
          {loggedIn && (
            <div className={styles.helloUserName}>
              {t("hello")} {loggedInUser.FirstName},
            </div>
          )}
          {loggedIn ? userMenu : siteDefaultMenu}
        </div>
        <div className={styles.bottom}>
          <Dropdown overlay={langMenu} placement="topCenter">
            <button className={styles.langButton} onClick={() => {}} />
          </Dropdown>
          {loggedIn ? siteDefaultMenu : siteInfoMenu}
        </div>
      </div>
    </div>
  );
};

export default Header;
