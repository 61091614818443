import React from "react";
import { withRouter } from "react-router-dom";
import { useTranslate } from "react-translate";

import { Button } from "../";

import styles from "./BlogPost.module.scss";


const BlogPost = ({ title, image, place, description, link, history }) => {
  const t = useTranslate("common");
  return (
    <div className={styles.blog}>
      <a href={link} target="_blank">
        <img src={image} alt="" />
      </a>
      <a href={link} target="_blank">
        <h1>
          <span>{title}</span> {place}
        </h1>
      </a>
      <p>{description}</p>
      <a href={link} target="_blank">
        <Button>{t("readMore")}</Button>
      </a>
    </div>
  );
};

export default withRouter(BlogPost);
