import React from "react";
import { Carousel, Col, Row } from "antd";
import MediaQuery,{ useMediaQuery } from "react-responsive";
import cx from "classnames";
import { EventItem } from "../../components";
import ApiService from "../../services/ApiService";
import { useState, useEffect, useRef } from "react";
import { useTranslate } from "react-translate";
import PubSub from 'pubsub-js';

import styles from "./Home.module.scss";

const SpecialEvents = ({ noTitle, getTitle }) => {
  const t = useTranslate("home");
  const tCommon = useTranslate("common");
  const isMobile768 = useMediaQuery({ query: "(max-width: 768px)" });
  const sliderSettings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToScroll: 1,
    variableWidth: true,
    slidesToShow: 1,
    draggable: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  let subscriptionToken = useRef();
  let userLang = localStorage.getItem("lang");
  if (userLang == null) {
    userLang = "tr";
  }
  
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await ApiService.getFeatured();
      var fetchedData = [];
      result.Item.forEach(element => {
        fetchedData.push({
          image: element.ImageUrl,
          title: element.Title,
          date: element.EventStartDate,
          place: element.EventCity,
          country: element.EventCountry,
          id: element.EventId,
          slug: element.Slug,
          isOnline: element.IsOnline,
          lang: userLang,
          price: element.EventPrice !== 0 ? element.EventPrice : null,
          currency: element.EventCurrency
        });
      });
      setData(fetchedData);
      PubSub.publish("FEATURED_LOADED", true);
    };

    subscriptionToken.current = PubSub.subscribe('LANG_CHANGED', function(key, val){
      userLang = val;
    });

    fetchData();

    return function cleanup() {
      PubSub.unsubscribe(subscriptionToken.current);
    }; 

  }, [userLang]);

  return (
    <div className={styles.specialEvents}>
      <div className="container">
        {!noTitle && (
          <h3 className={cx(styles.vertical, styles.specialEventsText)}>
            {isMobile768 ? (
              t("special")
            ) : (
              <img src={getTitle("SPECIAL_EVENTS")} alt="" />
            )}
          </h3>
        )}
        <MediaQuery maxWidth={991}>
          <Carousel {...sliderSettings}>
            {data.map(item => (
              <div className={styles.mobileBox}>
                <EventItem {...item} leftButtonText={tCommon("detailedInfo")} />
              </div>
            ))}
          </Carousel>
        </MediaQuery>
        <MediaQuery minWidth={992}>
          <Row gutter={35}>
            {data.map(item => (
              <Col key={item.id} span={8}>
                <EventItem {...item} leftButtonText={tCommon("detailedInfo")} />
              </Col>
            ))}
          </Row>
        </MediaQuery>
      </div>
    </div>
  );
};

export default SpecialEvents;
