import React from 'react';
import cx from 'classnames';
import { Button } from 'antd';

import styles from './Button.module.scss';

const ButtonWrapper = ({
  type = 'primary',
  children,
  className,
  disabled,
  onClick,
  ghost,
  color,
}) => {
  const classNames = cx(styles.btn, className, {
    [styles.primary]: type === 'primary',
    [styles.red]: color === 'red',
    [styles.white]: color === 'white',
  });

  return (
    <Button
      type={type}
      onClick={onClick}
      className={classNames}
      disabled={disabled}
      ghost={ghost}
    >
      {children}
    </Button>
  );
};

export default ButtonWrapper;
