import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Carousel } from "antd";
import { Link } from "react-router-dom";
import { useTranslate } from "react-translate";
import cx from "classnames";
import MediaQuery, { useMediaQuery } from "react-responsive";

import { Button, EventItem, BlogPost } from "../../components";

import useApp from "../../useApp";

import styles from "./Home.module.scss";

import { verticalTitles } from "./constantData";
import SpecialEvents from "./SpecialEvents";
import PubSub from "pubsub-js";
import ApiService from "../../services/ApiService";

const Home = () => {
  const { openSearch, openSearchWithText } = useApp();
  const t = useTranslate("home");
  const tCommon = useTranslate("common");

  const [closestEventsPage, setClosestEventsPage] = useState(1);
  const [closeEventsFetching, setCloseEventsFetching] = useState(false);

  const [populerEvents, setPopulerEvents] = useState([]);
  const [closeEvents, setCloseEvents] = useState([]);
  const [dailyPopulars, setDailyPopulars] = useState([]);
  const [blogPosts, setBlogPosts] = useState([]);
  const [closeEventsHasNext, setCloseEventsHasNext] = useState(true);
  const [places, setPlaces] = useState([]);

  let subscriptionToken = useRef();
  let userLang = localStorage.getItem("lang");
  if (userLang == null) {
    userLang = "tr";
  }

  const getTitle = (title) => {
    let verticalTitle = verticalTitles.filter(function (item) {
      return item.key === `TITLE_${title}_${userLang}`;
    });
    if (verticalTitle.length > 0) {
      return verticalTitle[0].image;
    }

    return null;
  };

  const onPlaceClicked = (placeName) => {
    openSearchWithText(placeName);
  };

  useEffect(() => {
    const fetchPlaces = async () => {
      var fetchedData = [];
      const result = await ApiService.getPlaces();
      result.Item.forEach((element) => {
        fetchedData.push({
          name: element.Name,
          image: element.ImageUrl,
          slug: element.Slug,
        });
      });
      setPlaces(fetchedData);
      PubSub.publish("PLACES_LOADED", true);
    };

    subscriptionToken.current = PubSub.subscribe("LANG_CHANGED", function (
      key,
      val
    ) {
      userLang = val;
    });

    fetchPlaces();

    return function cleanup() {
      PubSub.unsubscribe(subscriptionToken.current);
    };
  }, [userLang]);

  const fetchCloseEvents = async () => {
    setCloseEventsFetching(true);
    setClosestEventsPage(closestEventsPage + 1);
    const result = await ApiService.getClosest(closestEventsPage + 1);
    var criterioArray = [];
    result.Item.Data.forEach((element) => {
      closeEvents.push({
        image: element.ImageUrl,
        title: element.Title,
        date: element.EventStartDate,
        place: element.EventCity,
        country: element.EventCountry,
        id: element.EventId,
        isOnline: element.IsOnline,
        slug: element.Slug,
        lang: userLang,
        price: element.EventPrice !== 0 ? element.EventPrice : null,
        currency: element.EventCurrency,
      });
      criterioArray.push(element.EventId.toString());
    });
    setCloseEvents(closeEvents);
    setCloseEventsHasNext(result.Item.HasNext);
    setCloseEventsFetching(false);
    if (window.criteo_q) {
      var deviceType = /iPad/.test(navigator.userAgent)
        ? "t"
        : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
            navigator.userAgent
          )
        ? "m"
        : "d";

      window.criteo_q.push(
        { event: "setAccount", account: 68271 }, // You should never update this line
        { event: "setEmail", email: window.alivexUserEmail }, // Can be an empty string
        { event: "setSiteType", type: deviceType },
        { event: "viewList", item: criterioArray }
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await ApiService.getPopular();
      var fetchedData = [];
      result.Item.forEach((element) => {
        fetchedData.push({
          image: element.ImageUrl,
          title: element.Title,
          date: element.EventStartDate,
          place: element.EventCity,
          isOnline: element.IsOnline,
          country: element.EventCountry,
          id: element.EventId,
          slug: element.Slug,
          lang: userLang,
          price: element.EventPrice !== 0 ? element.EventPrice : null,
          currency: element.EventCurrency,
        });
      });
      setPopulerEvents(fetchedData);
      PubSub.publish("POPULAR_LOADED", true);
    };

    subscriptionToken.current = PubSub.subscribe("LANG_CHANGED", function (
      key,
      val
    ) {
      userLang = val;
    });

    fetchData();

    return function cleanup() {
      PubSub.unsubscribe(subscriptionToken.current);
    };
  }, [userLang]);

  useEffect(() => {
    const fetchClosest = async () => {
      const result = await ApiService.getClosest(closestEventsPage);
      var fetchedData = [];
      var criterioArray = [];
      result.Item.Data.forEach((element) => {
        fetchedData.push({
          image: element.ImageUrl,
          title: element.Title,
          date: element.EventStartDate,
          place: element.EventCity,
          country: element.EventCountry,
          isOnline: element.IsOnline,
          id: element.EventId,
          slug: element.Slug,
          lang: userLang,
          price: element.EventPrice !== 0 ? element.EventPrice : null,
          currency: element.EventCurrency,
        });
        criterioArray.push(element.EventId.toString());
      });
      setCloseEvents(fetchedData);
      setCloseEventsHasNext(result.Item.HasNext);
      if (window.criteo_q) {
        var deviceType = /iPad/.test(navigator.userAgent)
          ? "t"
          : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
              navigator.userAgent
            )
          ? "m"
          : "d";
        window.criteo_q.push(
          { event: "setAccount", account: 68271 }, // You should never update this line
          { event: "setEmail", email: window.alivexUserEmail }, // Can be an empty string
          { event: "setSiteType", type: deviceType },
          { event: "viewList", item: criterioArray }
        );
      }
      PubSub.publish("CLOSEST_LOADED", true);
    };

    subscriptionToken.current = PubSub.subscribe("LANG_CHANGED", function (
      key,
      val
    ) {
      userLang = val;
    });

    fetchClosest();

    return function cleanup() {
      PubSub.unsubscribe(subscriptionToken.current);
    };
  }, [userLang]);

  useEffect(() => {
    const fetchDailyPopular = async () => {
      const result = await ApiService.getDailyPopular();
      var fetchedData = [];
      result.Item.forEach((element) => {
        var titleParts = element.Title.split(" ");
        var title = titleParts.slice(0, titleParts.length - 1).join(" ");
        var lastTitlePart = titleParts[titleParts.length - 1];

        fetchedData.push({
          image: element.ImageUrl,
          title: title,
          titleLastPart: lastTitlePart,
          description: element.Description,
          link: element.Link,
        });
      });
      setDailyPopulars(fetchedData);
      PubSub.publish("DAILY_POPULAR_LOADED", true);
    };

    subscriptionToken.current = PubSub.subscribe("LANG_CHANGED", function (
      key,
      val
    ) {
      userLang = val;
    });

    fetchDailyPopular();

    return function cleanup() {
      PubSub.unsubscribe(subscriptionToken.current);
    };
  }, [userLang]);

  useEffect(() => {
    const fetchBlog = async () => {
      const result = await ApiService.getBlogPost();
      var fetchedData = [];
      result.forEach((element) => {
        let description = element.excerpt.rendered
          .replace(/<[^>]+>/g, "")
          .replace(/&#8220;/g, '"')
          .replace(/&#8221;/g, '"')
          .replace(/&#8217;/g, "'")
          .replace(/&#8230;/g, "...");
        var titleParts = element.title.rendered.split(" ");
        var title = titleParts.slice(0, titleParts.length - 1).join(" ");
        var lastTitlePart = titleParts[titleParts.length - 1];
        fetchedData.push({
          image: element._embedded["wp:featuredmedia"][0].source_url,
          title: title,
          place: lastTitlePart,
          description: description,
          link: element.link,
          country: element.EventCountry,
          id: element.EventId,
          slug: element.Slug,
          lang: userLang,
          price: element.EventPrice !== 0 ? element.EventPrice : null,
          currency: element.EventCurrency,
        });
      });
      setBlogPosts(fetchedData);
      PubSub.publish("BLOG_LOADED", true);
    };

    fetchBlog();
  }, []);

  const isMobile768 = useMediaQuery({ query: "(max-width: 768px)" });
  const homeVideoURL =
    "https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/alivex.mp4";
  const sliderSettings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
    slidesToShow: 1,
    swipe: true,
    draggable: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={styles.root}>
      <div className={styles.banner}>
        {isMobile768 ? (
          <div className={styles.imageBg}>
            <img
              src="https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/home-banner.jpg"
              alt=""
            />
          </div>
        ) : (
          <React.Fragment>
            <div className={styles.video}>
              <video controls={false} id="home-video" autoPlay loop muted>
                <source src={homeVideoURL}></source>
              </video>
            </div>
          </React.Fragment>
        )}
        <div className={styles.bannerContent}>
          <div className="container">
            <div className={styles.content}>
              <h1>{t("homeTitleFirst")}</h1>
              <h2>{t("homeTitleSecond")}</h2>
              <Button onClick={openSearch}>
                <span>{tCommon("search")}</span> "{tCommon("searchKey")}"
                <img
                  src="https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/search-dark.svg"
                  alt=""
                />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <SpecialEvents getTitle={getTitle} />

      {dailyPopulars.length > 0 ? (
        <div className={styles.populerEvents}>
          <div className="container">
            <h3 className={cx(styles.vertical, styles.populerEventsText)}>
              {isMobile768 ? (
                t("daily")
              ) : (
                <img src={getTitle("DAILY_POPULAR")} alt="" />
              )}
            </h3>
            <MediaQuery maxWidth={767}>
              <Carousel {...sliderSettings}>
                {dailyPopulars.map((item) => (
                  <div key={item.id} className={styles.mobileBox}>
                    <div className={styles.dailyPopular}>
                      <a href={item.link}>
                        <img src={item.image} alt={item.title} />
                      </a>
                      <a href={item.link}>
                        <h1>
                          <span>{item.title}</span> {item.titleLastPart}
                        </h1>
                      </a>
                      <p>{item.description}</p>
                      <a href={item.link}>
                        <Button>{tCommon("Discover")}</Button>
                      </a>
                    </div>
                  </div>
                ))}
              </Carousel>
            </MediaQuery>
            <MediaQuery minWidth={768}>
              <Row gutter={55}>
                {dailyPopulars.map((item) => (
                  <Col key={item.id} xs={24} sm={12} md={12}>
                    <div className={styles.dailyPopular}>
                      <a href={item.link}>
                        <img src={item.image} alt={item.title} />
                      </a>
                      <a href={item.link}>
                        <h1>
                          <span>{item.title}</span> {item.titleLastPart}
                        </h1>
                      </a>
                      <p>{item.description}</p>
                      <a href={item.link}>
                        <Button>{tCommon("Discover")}</Button>
                      </a>
                    </div>
                  </Col>
                ))}
              </Row>
            </MediaQuery>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      {places.length > 0 ? (
        <div className={styles.placesWrapper}>
          <div className="container">
            <h3 className={cx(styles.vertical, styles.placesWrapperText)}>
              {isMobile768 ? (
                t("places")
              ) : (
                <img src={getTitle("PLACES")} alt="" />
              )}
            </h3>
            <MediaQuery maxWidth={767}>
              <Carousel {...sliderSettings}>
                {places.map((item) => (
                  <div className={styles.mobileBox}>
                    <a
                      href={
                        userLang === "tr"
                          ? "/tr/" + item.slug
                          : "/en/" + item.slug
                      }
                      className={styles.place}
                    >
                      <img src={item.image} alt="" />
                      <h2>{item.name}</h2>
                    </a>
                  </div>
                ))}
              </Carousel>
            </MediaQuery>
            <MediaQuery minWidth={768}>
              <Row gutter={24}>
                {places.map((item) => (
                  <Col key={item.name} span={6}>
                    <div className={styles.mobileBox}>
                      <a
                        href={
                          userLang === "tr"
                            ? "/tr/" + item.slug
                            : "/en/" + item.slug
                        }
                        className={styles.place}
                      >
                        <img src={item.image} alt="" />
                        <h2>{item.name}</h2>
                      </a>
                    </div>
                  </Col>
                ))}
              </Row>
            </MediaQuery>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      <div className={styles.intro}>
        {t("middleBannerText", { span: <span>{t("yourEvent")}</span> })}{" "}
        <a
          href={
            userLang === "tr" ? "/tr/organizer" : "/en/organizer"
          }
        >
          <Button color="red">{tCommon("learnMore")}</Button>
        </a>
      </div>

      {populerEvents.length > 0 ? (
        <div className={styles.populerEvents}>
          <div className="container">
            <h3 className={cx(styles.vertical, styles.populerEventsText)}>
              {isMobile768 ? (
                t("populer")
              ) : (
                <img src={getTitle("POPULAR_EVENTS")} alt="" />
              )}
            </h3>
            <MediaQuery maxWidth={767}>
              <Carousel {...sliderSettings}>
                {populerEvents.map((item) => (
                  <div key={item.id} className={styles.mobileBox}>
                    <EventItem
                      {...item}
                      leftButtonText={tCommon("detailedInfo")}
                    />
                  </div>
                ))}
              </Carousel>
            </MediaQuery>
            <MediaQuery minWidth={768}>
              <Row gutter={55}>
                {populerEvents.map((item) => (
                  <Col key={item.id} xs={24} sm={12} md={12}>
                    <EventItem
                      {...item}
                      leftButtonText={tCommon("detailedInfo")}
                    />
                  </Col>
                ))}
              </Row>
            </MediaQuery>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      <div className={styles.closeEvents}>
        <div className="container">
          <h3 className={cx(styles.vertical, styles.closeEventsText)}>
            {isMobile768 ? (
              t("closest")
            ) : (
              <img src={getTitle("CLOSE_EVENTS")} alt="" />
            )}
          </h3>
          <Row gutter={24} type="flex">
            {closeEvents.map((item) => (
              <Col key={item.id} xs={24} sm={12} md={8} lg={6}>
                <EventItem
                  {...item}
                  leftButtonText={tCommon("detailedInfo")}
                  withBottomMargin
                />
              </Col>
            ))}
          </Row>
          {closeEventsFetching && (
            <span className="loader">
              <span className="loader-inner"></span>
            </span>
          )}
        </div>
      </div>
      {closeEventsHasNext && (
        <Button
          color="red"
          className={styles.moreButton}
          onClick={fetchCloseEvents}
          disabled={closeEventsFetching}
        >
          + {tCommon("more")}
        </Button>
      )}

      {blogPosts.length > 0 ? (
        <div className={styles.blogWrapper}>
          <div className="container">
            <h3 className={cx(styles.vertical, styles.blogWrapperText)}>
              {isMobile768 ? t("texts") : <img src={getTitle("BLOG")} alt="" />}
            </h3>
            <Row gutter={16} type="flex">
              {blogPosts.map((item) => (
                <Col key={item.id} md={16} xs={24}>
                  <BlogPost {...item} />
                </Col>
              ))}
            </Row>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Home;
