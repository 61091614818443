export const places = [
  {
    image:
      "https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/istanbul.0b78950d.jpg",
    name: "İstanbul"
  },
  {
    image:
      "https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/adana.jpg",
    name: "Adana"
  },
  {
    image:
      "https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/hatay.jpg",
    name: "Hatay"
  },
  {
    image:
      "https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/Antalya.jpg",
    name: "Antalya"
  }
];

export const categories = [
  {
    key: "Online",
    image: "https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/online-event.jpg",
    isOnline: true
  },
  {
    key: "Running",
    image: "https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/running.jpg",
    isOnline: false
  },
  {
    key: "Triathlon",
    image: "https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/triatlon.jpg",
    isOnline: false
  },
  {
    key: "Swimming",
    image: "https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/swimming.jpg",
    isOnline: false
  },
  {
    key: "Cycling",
    image: "https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/cycling.jpg",
    isOnline: false
  },
  {
    key: "Multisport",
    image: "https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/multisport.jpg",
    isOnline: false
  },
  {
    key: "CrossFit",
    image: "https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/functional_tra%C4%B1n%C4%B1ng.jpg",
    isOnline: false
  },
];

export const verticalTitles = [
  {
    key: "TITLE_BLOG_tr",
    image:"https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/vertical-titles/blog_tr.svg"
  },
  {
    key: "TITLE_BLOG_en",
    image:"https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/vertical-titles/blog_en.svg"
  },
  {
    key: "TITLE_PLACES_tr",
    image:"https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/vertical-titles/places_tr.svg"
  },
  {
    key: "TITLE_PLACES_en",
    image:"https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/vertical-titles/places_en.svg"
  },
  {
    key: "TITLE_POPULAR_EVENTS_tr",
    image:"https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/vertical-titles/popular_events_tr.svg"
  },
  {
    key: "TITLE_POPULAR_EVENTS_en",
    image:"https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/vertical-titles/popular_events_en.svg"
  },
  {
    key: "TITLE_CLOSE_EVENTS_tr",
    image:"https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/vertical-titles/close_events_tr.svg"
  },
  {
    key: "TITLE_CLOSE_EVENTS_en",
    image:"https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/vertical-titles/close_events_en.svg"
  },
  {
    key: "TITLE_SPECIAL_EVENTS_tr",
    image:"https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/vertical-titles/special_events_tr.svg"
  },
  {
    key: "TITLE_SPECIAL_EVENTS_en",
    image:"https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/vertical-titles/special_events_en.svg"
  },
  {
    key: "TITLE_DAILY_POPULAR_tr",
    image:"https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/vertical-titles/daily_tr.svg"
  },
  {
    key: "TITLE_DAILY_POPULAR_en",
    image:"https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/vertical-titles/daily_en.svg"
  }
];