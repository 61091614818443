import React, { useContext } from 'react'

const AppContext = React.createContext({})

export const AppProvider = AppContext.Provider;
export const AppConsumer = AppContext.Consumer;

const useApp = () => {
  const context = useContext(AppContext);

  return context;
};

export default useApp;
