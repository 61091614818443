import { configVariables } from "../configs/constants";

const ApiService = {
  getFeatured: async function() {
    let userLang = localStorage.getItem("lang");
    if (userLang == null) {
      userLang = "tr";
    }
    const res = await fetch(
      configVariables.API_URL + "/api/featured/" + userLang
    );
    const responseData = await res.json();
    return responseData;
  },
  getPopular: async function(){
    let userLang = localStorage.getItem("lang");
    if (userLang == null) {
      userLang = "tr";
    }
    const res = await fetch(
      configVariables.API_URL + "/api/activities/popular/" + userLang
    );
    const responseData = await res.json();
    return responseData;
  },
  getClosest: async function(page){
    let userLang = localStorage.getItem("lang");
    if (userLang == null) {
      userLang = "tr";
    }
    let take = 8;
    const res = await fetch(
      configVariables.API_URL + "/api/activities/home/" + userLang + "/" + page + "/" + take
    );
    const responseData = await res.json();
    return responseData;
  },
  getBlogPost: async function(){

    const res = await fetch(
      "https://blog.alivex.com/wp-json/wp/v2/posts?per_page=1&page=1&_embed=true"
    );
    const responseData = await res.json();
    return responseData;
  },
  search: async function(text){
    let userLang = localStorage.getItem("lang");
    if (userLang == null) {
      userLang = "tr";
    }
    const res = await fetch(
        configVariables.API_URL + "/api/activities/search/" + userLang + "/" + encodeURI(text) 
      );
      const responseData = await res.json();
      return responseData;
  },
  searchOnline: async function(){
    let userLang = localStorage.getItem("lang");
    if (userLang == null) {
      userLang = "tr";
    }
    const res = await fetch(
        configVariables.API_URL + "/api/activities/online/" + userLang
      );
      const responseData = await res.json();
      return responseData;
  },
  getSearchPopular: async function(){
    let userLang = localStorage.getItem("lang");
    if (userLang == null) {
      userLang = "tr";
    }
    const res = await fetch(
        configVariables.API_URL + "/api/activities/search/popular/" + userLang
      );
      const responseData = await res.json();
      return responseData;
  },
  getUser: async function(){
    window.alivexUserEmail = "";
      try {
        const res = await fetch(
            configVariables.API_URL + "/api/user/info" 
          );
          const responseData = await res.json();
          window.alivexUserEmail = responseData.Item.Email;
          return responseData;      
      } catch (error) {
          return null;
      }
  },
  getDailyPopular: async function(){
    let userLang = localStorage.getItem("lang");
    if (userLang == null) {
      userLang = "tr";
    }
    const res = await fetch(
      configVariables.API_URL + "/api/activities/home/daily/" + userLang
    );
    const responseData = await res.json();
    return responseData;
  },
  getPlaces: async function(){
    let userLang = localStorage.getItem("lang");
    if (userLang == null) {
      userLang = "tr";
    }
    const res = await fetch(
        configVariables.API_URL + "/api/places/" + userLang
      );
      const responseData = await res.json();
      return responseData;
  },
};
export default ApiService;
