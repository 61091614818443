import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { TranslatorProvider } from "react-translate";

import { Header, Footer, Search, AcceptCookies } from "./components";

import Home from "./containers/Home/Home";
import PubSub from "pubsub-js";

import { AppProvider } from "./useApp";

import en from "./locales/en.json";
import tr from "./locales/tr.json";

const defaultAcceptedCookie = window.localStorage.getItem("cookieAccepted");
const userLang = localStorage.getItem("lang");

const translations = {
  en,
  tr
};

function App() {
  const [lang, setLang] = useState(userLang || "tr");
  const [searchVisible, setSearchVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cookieAccepted, setCookieAccepted] = useState(
    Boolean(defaultAcceptedCookie)
  );
  const [searchText, setSearchText] = useState(null);

  let popularLoaded = useRef(false);
  let featuredLoaded = useRef(false);
  let closestLoaded = useRef(false);
  let dailyPopularLoaded = useRef(false);
  let placesLoaded = useRef(false);
  let popularSubscriptionToken = useRef();
  let closestSubscriptionToken = useRef();
  let dailyPopularSubscriptionToken = useRef();
  let featuredSubscriptionToken = useRef();
  let placesSubscriptionToken = useRef();
  let allImagesLoad = useRef(false);

  const openSearch = () => setSearchVisible(true);
  const closeSearch = () => {
    setSearchVisible(false)
    setSearchText(null);
  };
  const openSearchWithText = text => {
    setSearchText(text);
    setSearchVisible(true);
  };

  const translation = translations[lang];
  document.documentElement.lang = lang;

  useEffect(() => {
    document.body.style.overflow = "hidden";

    const images = document.querySelectorAll("img");
    const videos = document.querySelectorAll("video");

    const hasVideo = !!videos;
    const hasImages = !!images;

    let mediasLoaded = 0;
    const medias = [...(hasImages ? images : []), ...(hasVideo ? videos : [])];

    medias.forEach(media => {
      media.addEventListener(
        media.nodeName === "IMG" ? "load" : "loadeddata",
        imageLoaded
      );
    });

    function imageLoaded() {
      mediasLoaded++;
      if (mediasLoaded === images.length + (hasVideo ? videos.length : 0)) {
        allImagesLoad.current = true;
        closeLoading();
      }
    }

    function closeLoading() {
      if (
        allImagesLoad &&
        popularLoaded.current &&
        closestLoaded.current &&
        dailyPopularLoaded.current &&
        featuredLoaded.current && 
        placesLoaded.current
      ) {
        setLoading(false);
        document.body.style.overflow = "initial";
      }
    }

    placesSubscriptionToken.current = PubSub.subscribe(
      "PLACES_LOADED",
      function(key, val) {
        placesLoaded.current = true;
        closeLoading();
      }
    );

    popularSubscriptionToken.current = PubSub.subscribe(
      "POPULAR_LOADED",
      function(key, val) {
        popularLoaded.current = true;
        closeLoading();
      }
    );

    closestSubscriptionToken.current = PubSub.subscribe(
      "CLOSEST_LOADED",
      function(key, val) {
        closestLoaded.current = true;
        closeLoading();
      }
    );

    dailyPopularSubscriptionToken.current = PubSub.subscribe(
      "DAILY_POPULAR_LOADED",
      function(key, val) {
        dailyPopularLoaded.current = true;
        closeLoading();
      }
    );

    featuredSubscriptionToken.current = PubSub.subscribe(
      "FEATURED_LOADED",
      function(key, val) {
        featuredLoaded.current = true;
        closeLoading();
      }
    );

    return function cleanup() {
      PubSub.unsubscribe(popularSubscriptionToken.current);
      PubSub.unsubscribe(closestSubscriptionToken.current);
      PubSub.unsubscribe(featuredSubscriptionToken.current);
      PubSub.unsubscribe(placesSubscriptionToken.current);
      PubSub.unsubscribe(dailyPopularSubscriptionToken.current);
    };
  }, []);

  const value = {
    searchVisible,
    closeSearch,
    openSearch,
    openSearchWithText,
    lang,
    setLang
  };

  return ( 
    <div className="App">
      <AppProvider value={value}>
        <TranslatorProvider translations={{ locale: lang, ...translation }}>
          {loading && (
            <div className="app-loader">
              <div className="app-loader-content">
                <span className="loader">
                  <span className="loader-inner"></span>
                </span>
              </div>
            </div>
          )}
          <Router>
            <Header />
            <main className="main">
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/Home/Index" component={Home} />
              </Switch>
            </main>
            <Footer />
            {searchVisible && <Search onClose={closeSearch} textToSearch={searchText} />}
            {!cookieAccepted && (
              <AcceptCookies
                onAccept={() => {
                  setCookieAccepted(true);
                  window.localStorage.setItem("cookieAccepted", true);
                }}
              />
            )}
          </Router>
        </TranslatorProvider>
      </AppProvider>
    </div>
  );
}

export default App;
