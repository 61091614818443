import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Modal } from "antd";
import { useTranslate } from "react-translate";

import styles from "./Search.module.scss";

import { categories } from "../../containers/Home/constantData";
import { EventItem } from "..";
import ApiService from "../../services/ApiService";

const Search = ({ onClose, textToSearch }) => {
  const [searchText, setSearchText] = useState("");
  const [searchCategory, setSearchCategory] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const [searchingFinished, setSearchingFinished] = useState(false);
  const [suggestedSearches, setSuggestedSearches] = useState([]);
  const [loading, setLoading] = useState(false);

  const t = useTranslate("common");

  const debouncedSearchTerm = useDebounce(searchText, 400);
  let imagesLoaded = useRef(0);
  let suggestSearchesFetched = useRef(false);

  const imageLoaded = function() {
    imagesLoaded.current++;
    closeLoading();
  };

  const closeLoading = function() {
    if(textToSearch != null){
      setLoading(false);
      return;
    }
    if (imagesLoaded.current === categories.length && suggestSearchesFetched.current) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (textToSearch != null) {
      search(textToSearch);
    }else{
      setLoading(true);
    }
  }, []);

  useEffect(() => {
    const search = async () => {
      setSearching(true);
      setSearchingFinished(false);
      setSearchResults([]);
      let userLang = localStorage.getItem("lang");
      if (userLang == null) {
        userLang = "tr";
      }
      var result = null;

      if(searchCategory != null && searchCategory.isOnline){
        result = await ApiService.searchOnline();
      } else {
        result = await ApiService.search(debouncedSearchTerm);
      }
      
      var fetchedData = [];
      var criterioArray = [];
      result.Item.forEach(element => {
        fetchedData.push({
          image: element.ImageUrl,
          title: element.Title,
          date: element.EventStartDate,
          place: element.EventCity,
          country: element.EventCountry,
          isOnline: element.IsOnline,
          id: element.EventId,
          slug: element.Slug,
          lang: userLang,
          price: element.EventPrice !== 0 ? element.EventPrice : null,
          currency: element.EventCurrency
        });
        criterioArray.push(element.EventId.toString());
      });
      setSearching(false);
      setSearchingFinished(true);
      setSearchResults(fetchedData);
      setSearchCategory(null);
      if(window.criteo_q && criterioArray.length > 0) {
        var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
  
        window.criteo_q.push(
          { event: "setAccount", account: 68271}, // You should never update this line
          { event: "setEmail", email: window.alivexUserEmail }, // Can be an empty string 
          { event: "setSiteType", type: deviceType},
          { event: "viewList", item: criterioArray });
      }
    };
    if (debouncedSearchTerm) {
      search();
    } else {
      setSearchResults([]);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const getSuggestedSearches = async () => {
      let userLang = localStorage.getItem("lang");
      if (userLang == null) {
        userLang = "tr";
      }
      var result = await ApiService.getSearchPopular();
      var fetchedData = [];
      result.Item.forEach(element => {
        fetchedData.push({
          title: element.Title,
          key: element.Title,
          isOnline: false
        });
      });
      setSuggestedSearches(fetchedData);
      suggestSearchesFetched.current = true;
      closeLoading();
    };
    getSuggestedSearches();
  }, []);

  const search = async text => {
    setSearchText(text);
  };

  const categoryClicked = category => {
    setSearchCategory(category);
    setSearchText(category.key);
  };

  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
      () => {
        // Update debounced value after delay
        setSearchingFinished(false);
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);

        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
  }

  return (
    <Modal
      className={styles.root}
      footer={null}
      visible
      width="100%"
      closable={false}
    >
      {loading && (
        <div className="app-loader">
          <div className="app-loader-content">
            <span className="loader">
              <span className="loader-inner"></span>
            </span>
          </div>
        </div>
      )}
      <header className={styles.header}>
        <div className={styles.logo}>
          <img
            src="https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/logo.svg"
            alt=""
            onClick={() => onClose()}
          ></img>
        </div>
        <button className={styles.closeButton} onClick={() => onClose()}>
          <img
            src="https://alivex-cdn.s3.eu-central-1.amazonaws.com/images/red-close.svg"
            alt=""
          />
        </button>
      </header>
      <main className={styles.searcWrapper}>
        <div className={styles.field}>
          <input
            value={searchText}
            onChange={e => search(e.target.value)}
            placeholder={t("typeToSearch")}
          />
        </div>
        {!searchText && (
          <div className={styles.searchResults}>
            <h3>{t("suggestedSearches")}</h3>
            <ul>
              {suggestedSearches.map(item => (
                <li>
                  <button onClick={() => categoryClicked(item)}>
                    {item.title}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
        {searchText && !searching && searchingFinished && searchResults.length === 0 && (
        <div className={styles.row}>
          <div className={styles.emptyView}>
            <strong>{t("NoResultFound")}</strong>
            <span>
              {t("NoResultFoundExplanation", {
                span: <span>{searchText}</span>
              })}
            </span>
          </div>
        </div>
      )}
      </main>
      {searching && (
        <span className="loader">
          <span className="loader-inner"></span>
        </span>
      )}
      {searchText ? (
        <div className={styles.resultEvents}>
          <div className={styles.row}>
            {searchResults.map(item => (
              <div className={styles.column}>
                <EventItem
                  key={item.id}
                  {...item}
                  leftButtonText={t("detailedInfo")}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={styles.categories}>
          {categories.map(item => (
            <Link onClick={() => categoryClicked(item)}>
              <div className={styles.category}>
                <img src={item.image} alt="" onLoad={() => imageLoaded()} />
                <h3>{t(item.key)}</h3>
              </div>
            </Link>
          ))}
        </div>
      )}
    </Modal>
  );
};

export default Search;
