import React from "react";
import cx from "classnames";
import { withRouter } from "react-router-dom";
import { useTranslate } from "react-translate";
import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/tr";

import Truncate from 'react-truncate';
import { Button } from "../";

import styles from "./EventItem.module.scss";

const EventItem = ({
  image,
  title,
  date,
  place,
  country,
  price,
  currency,
  isOnline,
  id,
  slug,
  lang,
  history,
  leftButtonText,
  withBottomMargin
}) => {
  const t = useTranslate("common");

  moment.locale(lang);

  return (
    <div className={cx(styles.root, { [styles.withMargin]: withBottomMargin })}>
    {isOnline && (
            <div className={styles.online}>
              <span className={styles.title}>ONLINE</span>
            </div>
      )}
      <div></div>
      <a href={"/" + lang + "/" + slug}>
        <img src={image} alt={title} />
      </a>
      <a href={"/" + lang + "/" + slug} className={styles.title}>
        <h1>{title.length < 50 ? title : title.substring(0,50) + "..." }</h1>
      </a>
      <div className={styles.date}>{moment(date).format("D MMMM dddd")}</div>
      {!isOnline && (
        <div className={styles.place}>
          {place}, {country}
        </div>
      )}
      {isOnline && (
        <div className={styles.place}>
          {t("OnlineEvent")}  
        </div>
      )}
      <div className={styles.bottom}>
        <a href={"/" + lang + "/" + slug}>
          <Button className={styles.infoButton}>{leftButtonText}</Button>
        </a>
      
        <div className={styles.priceWrapper}>
          {price && lang === "tr" && (
            <div className={styles.price}>
              <strong>
                {price} {currency}
                <span>'{t("den")}</span>
              </strong>
              <span>{t("priceStartingFrom")}</span>
            </div>
          )}
          {!price && (
            <div className={styles.price}>
              <strong>{t("FREE")}</strong>
            </div>
          )}
          {price && lang === "en" && (
            <div className={styles.price}>
              <span>{t("priceStartingFrom")}</span>
              <strong>
                {price} {currency}
              </strong>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(EventItem);
