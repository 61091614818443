import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-translate';

import styles from './AcceptCookies.module.scss';
import { Button } from '../';

const AcceptCookies = ({ onAccept }) => {
  const t = useTranslate('common');
  
  let language = localStorage.getItem("lang");
  if (language == null) {
    language = "tr";
  }

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <p>{t('cookieText')}</p>
        <div className={styles.actions}>
          <Button
            color="white"
            onClick={onAccept}
          >
            {t('accept')}
          </Button>
          <a target="_blank" href={language === "tr" ? "https://alivex-cdn.s3.eu-central-1.amazonaws.com/agreements/ALIVEX_CookiePolicy_TR.pdf" : "https://alivex-cdn.s3.eu-central-1.amazonaws.com/agreements/ALIVEX_CookiePolicy_EN.pdf"}>
            {t('more')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default AcceptCookies;
